<template>
  <div id="app">
    <v-app id="inspire">
      <v-navigation-drawer v-model="drawer" app :width="300">
        <v-list height="180" class="mt-3">
          <a-row type="flex" justify="center" align="middle">
            <a-col :span="24" class="centerdiv">
              <!-- <v-avatar size="120">
                <img src="../assets/logo_color.png" />
              </v-avatar> -->
              <v-img
                src="../assets/e-storage.png"
                icon
                max-height="100px"
                max-width="100px"
              ></v-img>
            </a-col>
            <a-col :span="24" class="centerdiv">
              <p style="font-weight: 400; font-size: 18px">{{ username }}</p>
            </a-col>
          </a-row>
        </v-list>
        <v-divider></v-divider>
        <a-menu
          mode="vertical"
          v-if="
            checkuser.user.username == 'admin' ||
              checkuser.user.username == 'admindonut'
          "
        >
          <a-menu-item
            v-for="item in Navigator"
            :key="item.key"
            @click="ChangePage(item)"
          >
            <a-icon :type="item.icon" />
            <span class="pl-5">{{ item.text }}</span>
          </a-menu-item>
        </a-menu>
        <a-menu mode="vertical" v-else>
          <a-menu-item
            v-for="item in Navigator"
            :key="item.key"
            @click="ChangePage(item)"
            v-show="checkuser.user.bookType == item.key"
          >
            <a-icon :type="item.icon" />
            <span class="pl-5">{{ item.text }}</span>
          </a-menu-item>
        </a-menu>
        <!-- <a-menu mode="inline" @click="handleClick">
          <a-sub-menu
            @titleClick="titleClick"
            v-for="item in Navigator"
            :key="item.key"
          >
            <span slot="title">
              <span>{{ item.headerName }}</span>
            </span>
            <a-menu-item
              v-for="menuList in item.menuItem"
              :key="menuList.key"
              @click="ChangePage(menuList)"
            >
              <a-icon :type="menuList.icon" />
              {{ menuList.text }}
            </a-menu-item>
          </a-sub-menu>
        </a-menu> -->
      </v-navigation-drawer>
      <v-app-bar app color="#0074AA">
        <v-app-bar-nav-icon
          @click.stop="drawer = !drawer"
          style="color: white"
        ></v-app-bar-nav-icon>
        <h3
          style="color: white; font-weight: 800; font-size: 16px"
          class="pt-1"
        >
          ระบบสารบรรณอิเล็กทรอนิกส์ เทศบาลตำบลนาม่อง ปี
          {{ yearSelected }}
        </h3>
        <v-spacer></v-spacer>
        <v-btn class="mr-2" text dark @click="goToSelectYear()"
          >เลือกชุดแฟ้มเอกสาร</v-btn
        >
        <v-btn color="white" text @click="Logout()"
          >ออกจากระบบ<a-icon type="import" class="ml-2"
        /></v-btn>
      </v-app-bar>
      <v-main class="div_overflow">
        <!-- <v-container grid-list-xs> -->
        <div class="pa-4">
          <router-view></router-view>
        </div>
        <!-- </v-container> -->
      </v-main>
    </v-app>
  </div>
</template>
<script>
import { Decode } from "@/services";
export default {
  data() {
    return {
      drawer: true,
      Header: "",
      // picture: "",
      username: "",
      yearSelected: "",
      checkuser: [],
      NavigatorDefault: [
        {
          key: "menu0",
          text: "ทะเบียนหนังสือรับ",
          path: "/ManageForm1",
          name: "ManageForm1",
          icon: "download",
        },
        {
          key: "menu1",
          text: "ทะเบียนหนังสือส่ง",
          path: "/ManageForm2",
          name: "ManageForm2",
          icon: "upload",
        },
        {
          key: "menu2",
          text: "ทะเบียนประกาศ",
          path: "/ManageForm3",
          name: "ManageForm3",
          icon: "notification",
        },
        {
          key: "menu3",
          text: "ทะเบียนคำสั่ง",
          path: "/ManageForm4",
          name: "ManageForm4",
          icon: "audit",
        },
        {
          key: "menu4",
          text: "ทะเบียนบันทึกข้อความ",
          path: "/ManageForm5",
          name: "ManageForm5",
          icon: "profile",
        },
        {
          key: "menu5",
          text: "บัญชีหนังสือส่งเก็บ",
          path: "/ManageForm6",
          name: "ManageForm6",
          icon: "database",
        },
        {
          key: "menu6",
          text: "ทะเบียนหนังสือเก็บ",
          path: "/ManageForm7",
          name: "ManageForm7",
          icon: "hdd",
        },
        {
          key: "menu7",
          text: "บัญชีส่งมอบหนังสือครบ ๒๐ ปี",
          path: "ManageForm8",
          name: "ManageForm8",
          icon: "bars",
        },
        {
          key: "menu8",
          text: "บัญชีหนังสือครบ ๒๐ ปี ที่ขอเก็บเอง",
          path: "ManageForm9",
          name: "ManageForm9",
          icon: "bars",
        },
        {
          key: "menu9",
          text: "บัญชีฝากหนังสือ",
          path: "/ManageForm10",
          name: "ManageForm10",
          icon: "file-protect",
        },
        {
          key: "menu10",
          text: "บัญชีหนังสือขอทำลาย",
          path: "/ManageForm11",
          name: "ManageForm11",
          icon: "delete",
        },
        {
          key: "menu11",
          text: "จัดการผู้ใช้งาน",
          path: "/ManageUser",
          name: "ManageUser",
          icon: "user",
        },
        {
          key: "menu22",
          text: "ผู้ใช้งาน",
          path: "/ManageUser",
          name: "ManageUser",
          icon: "user",
        },
      ],
      NavigatorAdmin: [
        {
          key: "menu0",
          text: "ทะเบียนหนังสือรับ",
          path: "/ManageForm1",
          name: "ManageForm1",
          icon: "download",
        },
        {
          key: "menu1",
          text: "ทะเบียนหนังสือส่ง",
          path: "/ManageForm2",
          name: "ManageForm2",
          icon: "upload",
        },
        {
          key: "menu2",
          text: "ทะเบียนประกาศ",
          path: "/ManageForm3",
          name: "ManageForm3",
          icon: "notification",
        },
        {
          key: "menu3",
          text: "ทะเบียนคำสั่ง",
          path: "/ManageForm4",
          name: "ManageForm4",
          icon: "audit",
        },
        {
          key: "menu4",
          text: "ทะเบียนบันทึกข้อความ",
          path: "/ManageForm5",
          name: "ManageForm5",
          icon: "profile",
        },
        {
          key: "menu5",
          text: "บัญชีหนังสือส่งเก็บ",
          path: "/ManageForm6",
          name: "ManageForm6",
          icon: "database",
        },
        {
          key: "menu6",
          text: "ทะเบียนหนังสือเก็บ",
          path: "/ManageForm7",
          name: "ManageForm7",
          icon: "hdd",
        },
        {
          key: "menu7",
          text: "บัญชีส่งมอบหนังสือครบ ๒๐ ปี",
          path: "ManageForm8",
          name: "ManageForm8",
          icon: "bars",
        },
        {
          key: "menu8",
          text: "บัญชีหนังสือครบ ๒๐ ปี ที่ขอเก็บเอง",
          path: "ManageForm9",
          name: "ManageForm9",
          icon: "bars",
        },
        {
          key: "menu9",
          text: "บัญชีฝากหนังสือ",
          path: "/ManageForm10",
          name: "ManageForm10",
          icon: "file-protect",
        },
        {
          key: "menu10",
          text: "บัญชีหนังสือขอทำลาย",
          path: "/ManageForm11",
          name: "ManageForm11",
          icon: "delete",
        },
        {
          key: "menu11",
          text: "จัดการผู้ใช้งาน",
          path: "/ManageUser",
          name: "ManageUser",
          icon: "user",
        },
      ],
      Navigator: [],
    };
  },
  created() {
    this.year = JSON.parse(Decode.decode(localStorage.getItem("yearSelected")));
    if (
      Object.prototype.hasOwnProperty.call(localStorage, "usersarabannamong")
    ) {
      this.yearSelected = JSON.parse(
        Decode.decode(localStorage.getItem("yearSelected"))
      );
      this.checkuser = JSON.parse(
        Decode.decode(localStorage.getItem("usersarabannamong"))
      );
      console.log("this.checkuser", this.checkuser.user.bookType);
      var checkuser = JSON.parse(
        Decode.decode(localStorage.getItem("usersarabannamong"))
      );
      if (
        checkuser.user.username == "admin" ||
        checkuser.user.username == "admindonut"
      ) {
        this.Navigator = this.NavigatorAdmin;
      } else {
        // this.Navigator = this.NavigatorDefault;

        console.log("user", checkuser);
        this.username = checkuser.user.username;
        console.log("username", this.username);

        this.Navigator = this.NavigatorDefault;

        this.CheckHeader();
      }
    } else {
      console.log("ไม่มี user");
      this.$router.push("/");
    }
  },
  methods: {
    openKeys(val) {
      console.log("openKeys", val);
    },
    handleClick(e) {
      console.log("click", e);
    },
    titleClick(e) {
      console.log("titleClick", e);
    },
    async ChangePage(val) {
      // // console.log('เข้า ChangePage', val)
      // // console.log(val, this.$router.currentRoute)
      if (val.name !== this.$router.currentRoute.name) {
        this.$router.push({ path: val.path }).catch(() => {});
      }
    },
    CheckHeader(val) {
      this.Header = val;
    },
    Logout() {
      localStorage.removeItem("usersarabannamong");
      window.location.reload();
    },
    goToSelectYear() {
      this.$router.push("selectYear");
    },
  },
};
</script>
<style scoped>
.centerdiv {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.div_overflow {
  overflow: auto;
  width: 100%;
  height: 94vh;
}
</style>
<style>
.v-application ul,
.v-application ol {
  padding: 0px 0px !important;
}
</style>
